<script>
import Layout from "../../layouts/main";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';

export default {

  components: {
    Layout,
    EasyDataTable
  },
  data() {
    return {
        headers:[
            { text: "Name", value: "staffname" },
            { text: "Date", value: "logdate" },
            { text: "Activity", value: "appactivity" },
        ],
        items: [
            { "staffname": "You", "dateLog": '10 August, 2023', timeLog: '03.00PM', "appactivity": "You re-submit your application"},
            { "staffname": "Staff", "dateLog": '15 July, 2023', timeLog: '02.00PM', "appactivity": "Your application seems to be Incomplete"},
            { "staffname": "Staff", "dateLog": '29 June, 2023', timeLog: '01.00PM', "appactivity": "Review your personal information step",},
            { "staffname": "Staff", "dateLog": '22 May, 2023', timeLog: '12.00AM', "appactivity": "Viewed your application",},
            { "staffname": "Staff", "dateLog": '20 May, 2023', timeLog: '05.00PM', "appactivity": "Your application has been Submitted. We will update you once your application will be changed.",},
        ],
        arrItem:[],
    }
  },
  created()
  {
    this.arrItem = this.items
  },
  methods: {
    filterStatus(){
      if(this.selectFilterSort==null)
      {
        this.arrItem = this.items
      }
      else{
        this.arrItem = this.items.filter((item)=>{
          return  item.level==this.selectFilterSort
      })
      }
    }
  }

};
</script>

<template>
  <Layout>
    <main class="py-4">
        <div class="row mb-3">
            <div class="col">
                <h2 class="font-size-22 text-theme-dark">View Log</h2>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <EasyDataTable
                :headers="headers"
                :items="arrItem"
                :search-value="searchValueTable"
                border-cell
                theme-color="#df6a0d"
                :rows-per-page="10"
                buttons-pagination
                table-class-name="table-custom-style"
                    >
                <template #item-logdate="{dateLog , timeLog }">
                <p class="mb-0"><strong>{{ dateLog}}</strong></p>
                <p class="mb-0">{{timeLog}}</p>
                </template>
                <!-- <template #item-appactivity="appactivity">
                    <p class="mb-0">{{appactivity}}</p>
                </template> -->
                </EasyDataTable>
            </div>
        </div>
    </main>
  </Layout>
</template>